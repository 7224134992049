window.sbapi = "https://easyapi.superduper.pk";
window.imagesapi = "http://admin.superduper.pk";
export const DepartmentApi = () => dispatch => {

    fetch(`${window.sbapi}/api/Department/DGSData?BusinessId=0000000001`)
        .then(res => res.json())
        .then(json => {
    dispatch({
        type: 'get_department_data',
        data: json.Data
    })})
}


export const PoductsApi = (dep, grup) => dispatch => {
    
    fetch(`${window.sbapi}/api/Product/ProductEcomData?DepartmentCode=${dep}&GroupCode=${grup}&SubGroupCode=&FromIndex=1&ToIndex=12&BusinessId=0000000001`)
        .then(res => res.json())
        .then(json => {
            const data = json.Data.map((item) => {
                item.qty = 1
                return { ...item }
            })
            dispatch({
                type: 'get_allproducts_data',
                data: data
            })
        }
        )
}


export const fetchApi = () => dispatch => {
    fetch(`${window.sbapi}/api/Product/PromoandNewArrivalProducts?BusinessId=0000000001`)
        .then(res => res.json())
        .then(json => {
            const data = json.Data;
            
            const newArrival=data.NewArrivalProducts.map((item) => {
                item.qty = 1
                return { ...item }
            })
            const promo=data.PromoProducts.map((item) => {
                item.qty = 1
                return { ...item }
            })
            dispatch({
                type: 'get_products_data',
                data: {promotional:promo,newArrival:newArrival}
            })
        }
        )
}

export const AddToCart = (data) => dispatch => {
    dispatch({
        type: 'add_to_cart',
        payload: data
    })
}

export const SetQty = (data) => dispatch => {
    dispatch({
        type: 'set_qty',
        payload: data
    })
}

export const RemoveItem = (data) => dispatch => {
    dispatch({
        type: 'remove_item',
        payload: data
    })
}

export const ClearCart = () => dispatch => {
    dispatch({
        type: 'clear_cart',

    })
}

export const ChangeName = (data) => dispatch => {
    dispatch({
        type: 'change_name',
        payload:data
    })
}


export const LoginData = (data) => dispatch => {
    dispatch({
        type: 'login_data_checkout',
        payload:data
    })
}





